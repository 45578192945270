[data-v-6f943dc1] .el-submenu__title {
  font-size: 18px;
}
[data-v-6f943dc1] .el-menu-item {
  font-size: 18px;
}
.icon-font-size[data-v-6f943dc1] {
  font-size: 22px;
}
.icon-img[data-v-6f943dc1] {
  width: 22px;
  height: 22px;
  margin-right: 4px;
}
